import React from 'react'
import "./Footer.css"
import logoPonpes from "../images/ni.png"
import { FaHome, FaMailBulk, FaPhoneAlt } from 'react-icons/fa'
import { MdMail } from "react-icons/md";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
        <footer className="w-100  flex-shrink-0">
      <div className="footer">
          <div className="container ">
            <div className="row gy-4 gx-5">
              <div className="col-lg-4 col-md-6">
              <img src={logoPonpes} width="150px" />
                <h5 className="h1 text-white mt-2">PPMTQNI</h5>
                <p className="small text-muted d-flex gap-3 mb-2">
                  <FaHome style={{fontSize:"30px" , color:"#FAFAFA"}}/> 
                  <span style={{color:"#fafafa"}}>
                 Jl. Jatibarang - Sitanggal Km 7, Dukuh Kedawon - Larangan - Brebes
                  </span>
                </p>

                <p className="small text-muted d-flex gap-3 mb-4">
                  <FaPhoneAlt style={{fontSize:"20px" , color:"#FAFAFA"}}/> 
                  <span style={{color:"#fafafa"}}>
               0857 0001 2892
                  </span>
                </p>

                <p className="small text-muted d-flex gap-3 mb-4">
                  <MdMail style={{fontSize:"23px" , color:"#FAFAFA"}}/> 
                  <span style={{color:"#fafafa"}}>
              ppmtqni@gmail.com
                  </span>
                </p>
                <p className="small  mb-0" style={{color:"#fafafa"}}>
                  © Copyrights. 2024
                </p>
              </div>
              <div className="col-lg-2 col-md-6">
                <h5 className="text-white mb-3">Tautan Internal</h5>
                <ul className="list-unstyled text-muted">
                  <li className='mb-1'>
                    <Link to="/beranda">Beranda</Link>
                  </li>
                  <li  className='mb-1'>
                  <Link to="/sambutan-pengasuh" >Sambutan Pengasuh</Link>
                  </li>
                  <li  className='mb-1'>
                  <Link to="/visi-misi"  >Visi & Misi</Link>

                  </li>
                  <li  className='mb-1'>
                  <Link to="/struktur-organisasi" >Struktur Organisasi</Link>
                  </li>

                  <li  className='mb-1'>
                  <Link to="/tenaga-pendidik" >Tenaga Pendidik</Link>
                  </li>

                  <li  className='mb-1'>
                  <Link to="/kurikulum-pesantren">Kurikulum Pesantren</Link>
                  </li>

                  <li  className='mb-1'>
                  <Link to="/ekstrakulikuler">Ekstrakurikuler</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6">
                <h5 className="text-white mb-3">Layanan Pondok</h5>
                <ul className="list-unstyled text-muted">
                  <li  className='mb-1'>
                  <Link to="/berita-terkini">Berita Terkini</Link>
                  </li>

                  <li  className='mb-1'>
                  <Link to="/agenda-mendatang">Agenda Mendatang</Link>
                  </li>

                  <li  className='mb-1'>
                  <Link to="/e-library">E-Library</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6">
                <h5 className="text-white mb-3">Lainnya</h5>
                <ul className="list-unstyled text-muted">
                  <li className='mb-1'>
                  <Link to="/info-daftar">Info Pendaftaran</Link>
                  </li>
                  <li  className='mb-1'>
                  <Link to="/daftar-online">Daftar Online</Link>
                  </li>
                  <li  className='mb-1'>
                  <Link to="/galeri" >Galeri</Link>
                  </li>
              
                </ul>
              
              </div>
            </div>
          </div>

      </div>
        </footer>
    </div>
  )
}

export default Footer