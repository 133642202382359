import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Beranda from './pages/beranda/Beranda';
import Profile from './pages/profile/Profile';
import DetailProfil from './pages/detail/DetailProfil';
import SambutanPengasuh from './pages/profile/SambutanPengasuh';
import VisiMisi from './pages/profile/VisiMisi';
import StrukturOrganisasi from './pages/profile/StrukturOrganisasi';
import DaftarPendidik from './pages/profile/DaftarPendidik';
import Ebook from './pages/ebook/Ebook';
import EbookRead from './pages/ebook/EbookRead';
import BeritaTerkini from './pages/informasi/BeritaTerkini';
import DetailBerita from './pages/informasi/DetailBerita';
import Galeri from './pages/galeri/Galeri';
import Ekstrakulikuler from './pages/akademik/Ekstrakulikuler';
import HubungiKami from './pages/hubungi/HubungiKami';
import DaftarOnline from './pages/pendaftaran/DaftarOnline';
import Prestasi from './pages/akademik/Prestasi';
import AgendaMendatang from './pages/informasi/AgendaMendatang';
import InfoDaftar from './pages/pendaftaran/InfoDaftar';
import PendidikanFormal from './pages/akademik/PendidikanFormal';
import KurikulumPesantren from './pages/akademik/KurikulumPesantren';






function App() {
  
  return (
    <Router>
      
      <Routes>
        <Route exact path="/" element={<Beranda />} />
        <Route exact path="/beranda" element={<Beranda />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/detail" element={<DetailProfil />} />

        <Route exact path="/sambutan-pengasuh" element={<SambutanPengasuh />} />
        <Route exact path="/visi-misi" element={<VisiMisi />} />
        <Route exact path="/struktur-organisasi" element={<StrukturOrganisasi />} />
        <Route exact path="/prestasi" element={<Prestasi />} />
        <Route exact path="/pendidikan-formal" element={<PendidikanFormal/>} />
        <Route exact path="/kurikulum-pesantren" element={<KurikulumPesantren />} />

        <Route exact path="/tenaga-pendidik" element={<DaftarPendidik />} />

        <Route exact path="/e-library" element={<Ebook />} />
        <Route exact path="/ebook-reader" element={<Ebook />} />
        <Route exact  path="/ebook-playread" element={<EbookRead/>} />

        <Route exact  path="/ekstrakulikuler" element={<Ekstrakulikuler/>} />

        <Route exact  path="/daftar-online" element={<DaftarOnline/>} />
        <Route exact  path="/info-daftar" element={<InfoDaftar/>} />
        

        <Route exact  path="/berita-terkini" element={<BeritaTerkini/>} />
        <Route exact  path="/detail-berita" element={<DetailBerita/>} />
        <Route exact  path="/agenda-mendatang" element={<AgendaMendatang/>} />
        <Route exact  path="/detail-agenda" element={<DetailBerita/>} />

        <Route exact  path="/galeri" element={<Galeri/>} />
        <Route exact  path="/detail-profil" element={<DetailProfil/>} />
        <Route exact  path="/hubungi-kami" element={<HubungiKami/>} />
        
      </Routes>
    </Router>
  )
}

export default App
